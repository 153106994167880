import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { jqxSchedulerModule }    from 'jqwidgets-ng/jqxscheduler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccueilComponent } from './accueil/accueil.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { CeComponent } from './ce/ce.component';
import { EvenementielComponent } from './evenementiel/evenementiel.component';
import { BonscadeauxComponent } from './bonscadeaux/bonscadeaux.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RgpdComponent } from './rgpd/rgpd.component';
import { HoraireComponent } from './horaire/horaire.component';
import { HttpClientModule } from '@angular/common/http';
import { AlertifyService } from './service/alertify.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    TarifsComponent,
    CeComponent,
    EvenementielComponent,
    BonscadeauxComponent,
    RgpdComponent,
    HoraireComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    jqxSchedulerModule,
    HttpClientModule
  ],
  providers: [
    AlertifyService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
