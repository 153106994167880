import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EmailService } from '../service/email.service';
import { Email } from '../interface/email';
import { AlertifyService } from '../service/alertify.service';

@Component({
  selector: 'app-evenementiel',
  templateUrl: './evenementiel.component.html',
  styleUrls: ['./evenementiel.component.css']
})
export class EvenementielComponent implements OnInit {

  constructor(private emailservice: EmailService, private alertifyService: AlertifyService) { }

  @ViewChild('nom', {static: true}) nom: ElementRef;
  @ViewChild('prenom', {static: true}) prenom: ElementRef;
  @ViewChild('tel', {static: true}) tel: ElementRef;
  @ViewChild('email', {static: true}) email: ElementRef;
  @ViewChild('categorie', {static: true}) categorie: ElementRef;
  @ViewChild('message', {static: true}) message: ElementRef;
  
  dataEmail:string;
  
  ngOnInit() {
  }

  sendEmail():void
  {
    let myEmailbis:Email={
      nom:this.nom.nativeElement.value,
      prenom:this.prenom.nativeElement.value,
      tel:this.tel.nativeElement.value,
      email:this.email.nativeElement.value,
      categorie:this.categorie.nativeElement.value,
      message:this.message.nativeElement.value};

      console.log(myEmailbis);
    this.emailservice.sendEmail(myEmailbis)
    .subscribe(
      (data:string) => this.dataEmail = data,
      (err:any) => console.log(err),
      ()=> {
        if(this.dataEmail)
        {
          this.alertifyService.success("Message envoyé!");
        }
        else
        {
          this.alertifyService.error("Erreur sur l'envoi du message.");
        }
      }
    );
  }

  cleanEmail():void{
    this.nom.nativeElement.value="";
      this.prenom.nativeElement.value="";
      this.tel.nativeElement.value="";
      this.email.nativeElement.value="";
      this.categorie.nativeElement.value="- Catégorie -";
      this.message.nativeElement.value="";
  }
}
