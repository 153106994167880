import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Email } from '../interface/email';

const optionRequete = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*','Content-Type': 'application/json; charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  myEmail:Email;

  private rest_email = "api/Message";

  constructor(private http: HttpClient) { }

  sendEmail(myEmail:Email)
  {
    return this.http.post(this.rest_email, myEmail);
  }
  
}
