import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hammam';

  constructor(private meta: Meta) {
    this.meta.addTag({ name: 'description', content: "Bienvenue au Ô Soins d'Orient" });
    this.meta.addTag({ name: 'author', content: 'cheklame' });
    this.meta.addTag({ name: 'keywords', content: 'Hammam, spa, sauna, esthétiques et soins corporels' });
  }
}
