import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ce',
  templateUrl: './ce.component.html',
  styleUrls: ['./ce.component.css']
})
export class CeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
