import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { EvenementielComponent } from './evenementiel/evenementiel.component';

const routes: Routes = [
  {path: 'accueil', component: AccueilComponent},
  {path: 'tarifs', component: TarifsComponent},
  {path: 'evenementiel', component: EvenementielComponent},
  { path: '**', component: AccueilComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
